import { combineReducers, createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import {queryPageByIdRequest, loginReducer, getByIdRequest, getRequest } from "./redux/reducers/index";
import newsReducer from './redux/reducers/base/newsReducer'

const reducer = combineReducers({
  examsQueryPageList: queryPageByIdRequest('exam'),
  examsProfileQueryPageList: queryPageByIdRequest('profile/exams'),
  newsQueryPageList: newsReducer,
  commentsQueryPageList: newsReducer,
	loginReducer: loginReducer,
	examResults: getByIdRequest('exam/detail'),
	studentList: getRequest('auth/user-list'),
	teacherList: getRequest('auth/teacher-list'),
	contactList: getRequest('auth/contact'),
	examResultsAll: getByIdRequest('exam/results/all'),
	profileData: getByIdRequest('profile'),

});



const store = createStore(reducer, applyMiddleware(thunk));

export default store;
